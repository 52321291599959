/*start*/

@font-face {
  font-family: 'PopR';
  src: url('/assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PopSB';
  src: url('/assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'PopM';
  src: url('/assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'PopL';
  src: url('/assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'PopEB';
  src: url('/assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'PopB';
  src: url('/assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PopBL';
  src: url('/assets/fonts/Poppins-Black.ttf') format('truetype');
}


:root {
  --blue: #40bc51;
  --BL: #160c26;
  --w: #fff;
  --br: #e1e1e1;
  --gr: #787f86;
  --BG: #f8fafc;
  --tb1: #F3F3F3;
  --tran_s: all ease-in-out 0.5s;
  --s1: 3px 5px 20px rgba(0, 0, 0, 0.05);
}

body {
  font-family: 'PopR';
  line-height: 25px;
  font-size: 15px;
  color: var(--BL);
  background: var(--BG);
}

html {
  overflow-x: hidden;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: var(--w) !important;
  /* background-color: var(--blue) !important;
  border-color: var(--bs-btn-active-border-color) !important; */
}

/* For dont select */
/* html {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */
/* For dont select */
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #40bc51 !important;
  color: white !important;
}

.modal-dialog.modal-sm {
  max-width: 1600px !important;
}

td.dataTables_empty {
  color: red !important;
}

/* pagination css start */

a.pagination1 {
  font-size: 15px;
  /* margin: 0px; */
  padding: 0px 31px;
  cursor: pointer;
}

.padginat_2 {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: 20px;

  align-items: center;
}

.padginat_2 ul {
  margin: 0;
}

.padginat_2 ul>li {
  display: inline-block;
  /* margin: 2px; */
}

.padginat_2 ul>li .btn {
  padding: 5px 14px;
  font-size: 14px;
  font-family: "PopM";
  border: none;
  color: var(--BL);
  margin-left: 4px;
}

.padginat_2 ul>li .btn:hover {
  background: #e1e1e1;
  color: var(--BL);
}

.padginat_2 ul>li .btn.active {
  background: var(--blue);
  color: var(--w);
}

/* pagination css End */

/* Loader */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--w);
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

#pageQty {
  cursor: pointer;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.loaderr2 {
  /* position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 25% 0; */
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* loader End */



/*start*/


/*custom*/
.mb-3 {
  margin-bottom: 1.2rem !important;
}

a {
  color: var(--blue);
  text-decoration: none;
}

label {
  font-weight: normal;
  color: var(--gr);
  margin-bottom: 5px;
}

a:hover,
a:focus {
  color: var(--blue);
  text-decoration: none;
}

.container-fluid {
  padding: 0 15px;
}

.img_r {
  max-width: 100%;
  max-height: 100%;
}

.ul_set {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 20px;
}

b,
strong {
  font-weight: normal;
  font-family: "PopM";
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
}

/*custom*/

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.btn_theme {
  background: var(--blue);
  color: var(--w) !important;
  box-shadow: none !important;
  padding: 6px 20px;
  font-size: 16px;
  font-family: "PopM";
}

.btn_theme:hover,
.btn_theme:focus,
.btn_theme:active {
  background: var(--blue);
  color: var(--w) !important;
  opacity: 0.9;
}

.btn_theme2 {
  /* background: #e5020d; */
  background: #20d807e5;
  color: var(--w) !important;
  box-shadow: none !important;
  padding: 6px 18px;
  font-size: 16px;
  font-family: "PopM";
}

.btn_theme2:hover,
.btn_theme2:focus,
.btn_theme2:active {
  /* background: #e5020d; */
  background: #20d807e5;
  color: var(--w) !important;
  opacity: 0.9;
}

.btn_r {
  border-radius: 40px;
}

.form-control {
  border-radius: 6px;
  height: 45px;
  padding: 8px 15px;
  box-shadow: none !important;
  font-size: 15px !important;
  color: var(--BL);
  font-family: "PopR";
  border: 1px solid var(--br) !important;
  background-color: var(--w) !important;
}

select.form-control {
  height: 45px !important;
  background-image: url(assets/img/img_3.png);
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 33px;
}

textarea.form-control {
  height: 100px;
}

.form-control:focus {
  border-color: var(--blue);
  color: var(--BL);
}

.btn-lg {
  padding: 7px 20px;
  font-family: "PopSB";
  font-size: 18px;
  border-radius: 6px;
}

.btn-md {
  padding: 6px 15px;
  font-size: 16px;
  font-family: "PopM";
}

.btn-sm {
  padding: 4px 10px;
  font-size: 14px;
}

.btn_r {
  border-radius: 40px;
}

.text_u {
  text-transform: uppercase;
}

.btn-block {
  width: 100%;
}

.login_img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: url(assets/img/bg1.png);
  background-size: cover;
  background-position: bottom;
}

.conten_set h4,
.conten_set h1,
.conten_set h2,
.conten_set h3 {
  margin-bottom: 20px;
  color: var(--BL);
  font-family: 'PopSB';
}

.conten_set p {
  color: var(--gr);
  font-size: 15px;
}

.conten_set ul {
  margin-bottom: 20px;
  color: var(--gr);
}

.conten_set ul li {
  margin-bottom: 10px;
}

.conten_set ul.check li {
  position: relative;
  padding-left: 30px;
  font-size: 15px;
}

.conten_set ul.check li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 18px;
  height: 18px;
  border: 2px solid var(--blue);
  border-radius: 100%;
}

/* login */
.login_box2.conten_set h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 28px
}

.login_box2.conten_set h2 span {
  display: block;
  font-size: 18px;
  font-family: "PopR";
  color: var(--gr);
  margin-top: 12px;
}

.login_box1 {
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.login_box1 .form-control {
  height: 55px;
}

.login_box1 .forget {
  font-size: 15px;
  text-align: right;
  font-family: "PopM";
}

.login_box1 .bott_line {
  margin: 0;
  color: var(--gr);
  margin-top: 15px;
  text-align: center;
}

.login_box1 .btn {
  /* border-radius: 0; */
  padding: 10px 20px;
}

.login_box1 .bott_line a {
  font-family: "PopM";
}

.logo_login {
  width: 215px;
  margin-bottom: 30px;
}

/* login */

/* sign_up */
.login_box1.sign_up h2 {
  font-size: 24px;
}

.login_box1.sign_up h2 span {
  font-size: 17px;
}

.login_box1 textarea.form-control {
  height: 90px;
}

/* sign_up */

/* otp */

.otp_nam .row {
  margin: 0 -7px;
}

.otp_nam .row .col-3 {
  padding: 0 7px;
}

.otp_nam .row .col-3 input {
  text-align: center;
}

/* otp */

/* dashboard */
.header {
  height: 70px;
  background: var(--w);
  border-bottom: 1px solid var(--br);
  border-left: 1px solid var(--br);
  position: fixed;
  top: 0;
  left: 300px;
  width: calc(100% - 300px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
  z-index: 2;
}

.user_profile img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 100%;
}

.dropdown-menu {
  min-width: 210px;
}

.menu {
  display: none;
}

.menu .btn {
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid var(--br);
  width: 43px;
  height: 43px;
  padding: 0;
  border-radius: 100%;
  box-shadow: none !important;
}

.user_profile .dropdown-item {
  padding: 8px 15px;
}

.user_profile .dropdown-menu i {
  font-size: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

.wrap_us {
  position: relative;
}

.sidebar {
  position: fixed;
  left: 0;
  width: 300px;
  background: var(--w);
  height: 100%;
  top: 0;
  border-right: 1px solid var(--br);
  z-index: 99;
}

.logo {
  padding: 10px 15px;
  background: var(--w);
  height: 70px;
  text-align: center;
  border-bottom: 1px solid var(--br);
}

.logo img {
  width: 100%;
  height: 43px;
  object-fit: contain;
}

/* .sidebar > .ul_set {
padding: 15px 10px;
} */
.sidebar>.ul_set>li>a {
  color: #475569;
  padding: 12px 15px;
  display: block;
  position: relative;
  padding-left: 46px;
}

.sidebar>.ul_set>li>a i {
  font-size: 23px;
  left: 12px;
  top: 13px;
  position: absolute;
}

.sidebar>.ul_set>li>a:hover {
  background: var(--BG);
  color: var(--blue);
}

.sidebar>.ul_set>li>a.active1 {
  background: var(--blue);
  color: var(--w);
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--BG);
  color: var(--blue);
}

.conten_main {
  padding-left: 300px;
  margin-top: 70px;
  padding-right: 0px;
  margin-bottom: 0;
  /* overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 70px); */
}

/* dashboard */

.haeder_1 {
  background: var(--w);
  border-bottom: 1px solid var(--br);
  padding: 10px 15px;
  position: relative;
}

.user {
  position: relative;
  padding-left: 60px;
}

.user span {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: rgba(104, 58, 183, 0.1);
  color: var(--blue);
  font-size: 24px;
  border-radius: 100%;
  font-family: "PopM";
  left: 0;
}

.user h4 {
  margin-bottom: 2px;
}

.user p {
  margin: 0;
  font-size: 16px;
}

.chat_list {
  padding: 10px 15px;
  overflow: hidden;
  position: relative;
}

.row1 {
  margin: 0 -10px;
  display: flex;
  overflow-x: auto;
}

.row1 .col-md-3m {
  width: 320px;
  padding: 0 10px;
  max-width: 320px;
  flex: 0 0 350px;
}


.box_1 .btn,
.message_us .btn {
  background: var(--w);
  color: var(--blue);
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  font-size: 19px;
  text-align: center;
  line-height: 30px;
}

.box_2 h4 {
  padding-right: 70px;
  position: relative;
  min-height: 30px;
  font-size: 16px;
  padding-top: 5px;
}

.box_2 h4>span {
  position: absolute;
  right: 0;
  top: 0;
}

.menu_d a::after {
  display: none !important;
}


.menu_d .dropdown-menu {
  left: auto !important;
  right: 0 !important;
  transform: none !important;
  top: 100% !important;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
}

.dropdown.menu_d li {
  margin: 0;
}

.dropdown.menu_d li a {
  font-size: 15px;
  font-weight: normal;
  font-family: "PopR";
  padding: 9px 15px;
  padding-left: 42px;
  position: relative;
  color: #4d4d4d;
}

.dropdown.menu_d li a i {
  position: absolute;
  left: 13px;
  font-size: 18px;
  top: 8px;
}

.dropdown.menu_d li a:hover {
  color: var(--blue);
  background: var(--BG)
}

.box_1 {
  height: calc(100vh - 170px);
  overflow-y: auto;
}

.message_us {
  background: var(--w);
  padding: 10px;
  border: 1px solid var(--br);
  border-radius: 10px;
  position: relative;
}

.message_us .dropdown.menu_d {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}

.icon_in {
  position: relative;
}

.icon_in img,
.icon_in i {
  width: 20px;
  height: 20px;
  object-fit: contain;
  position: absolute;
  left: 13px;
  top: 11px;
  margin: 0;
  font-size: 21px;
  color: var(--blue);
}

.icon_in .form-control {
  padding-left: 45px;
}

.message_us .form-control {
  background: transparent !important;
  font-size: 14px;
  font-size: 14px !important;
  height: 80px;
  resize: none
}

.message_us .icon_in i {
  left: 5px;
}

.message_us .icon_in .form-control,
.message_us .icon_in p {
  padding-left: 35px;
  padding-top: 10px;
}

.message_us {
  margin-bottom: 10px;
  max-height: 110px;
  min-height: 110px;
}

.message_us .btn {
  background: var(--BG);
}

.chat_modal {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  background: #fff;
  height: calc(100vh - 140px);
  border-left: 1px solid var(--br);
  transition: var(--tran_s);
  transform: translateX(100%);
}

.chat_hadi .user span {
  width: 35px;
  height: 35px;
  font-size: 17px;
  line-height: 35px;
  top: 3px;
}

.chat_hadi {
  background: #f9f9f9;
  border-bottom: 1px solid var(--br);
  padding: 10px 15px;
}

.chat_hadi .user {
  padding-left: 45px;
}

.chat_hadi .user h4 {
  font-size: 15px;
  margin-bottom: 0;
}

.chat_hadi .user p {
  font-size: 14px;
  line-height: normal;
}

.ul_set.option__ch li {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: normal;
}

.tabllle1 {
  display: table;
}

.ttrr {
  display: table-row;
}

.caht_body.ttrr {
  height: 100%;
}

.caht_body2 {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.caht_body3 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 15px;
}

.ul_set.option__ch li .namee1 {
  color: var(--BL);
  font-family: "PopM";
}

.namee2 {
  width: 150px;
}

.ul_set.option__ch li span {
  display: inline-block;
}

.chat_botm {
  background: #f9f9f9;
  border-top: 1px solid var(--br);
  padding: 10px 15px;
}

.chat_botm .form-control {
  background: var(--w) !important;
  border: 1px solid var(--br) !important;
  font-size: 14px !important;
  resize: none !important;
  height: 120px !important;
  padding-left: 15px !important;
}

.iocn_text {
  position: relative;
}

.left_img {
  position: absolute;
}

.left_img img {
  width: 20px;
}

.left_img {
  position: absolute;
  bottom: 10px;
  left: 15px;
}

.right_btn {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.user span img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.cottecttion .ul_set li .user {
  padding: 0;
  display: inline-block;
}

.cottecttion .ul_set li .user span {
  position: initial;
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 11px;
  line-height: 25px;
}

.close_h {
  background: #FF6600;
  width: 12px;
  height: 12px;
  display: inline-block;
  position: absolute;
  right: -3px;
  border-radius: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  top: -4px;
  line-height: 8px;
  font-size: 12px;
  color: var(--w);
  text-align: center;
}

.cottecttion .ul_set li {
  margin: 0;
  padding: 0 5px;
}

.cottecttion .ul_set {
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 15px;
}

.cottecttion .ul_set li .user.plus2 span {
  color: var(--w);
  font-size: 20px;
  background: var(--gr);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.cottecttion .ul_set li .user.conttt span {
  color: var(--BL);
  background: var(--w);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "PopM";
  font-size: 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.ul_set.chat_msg2 {
  margin: 0;
}

.ul_set.chat_msg2 li {
  position: relative;
  padding-right: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.ul_set.chat_msg2 li .user_n {
  position: absolute;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  background: rgba(104, 58, 183, 0.1);
  color: var(--blue);
  font-size: 16px;
  border-radius: 100%;
  font-family: "PopM";
  right: 0;
}

.messaged {
  padding: 10px;
  background: var(--BG);
  border-radius: 10px;
  width: auto;
  display: inline-block;
}

.messaged h4 {
  font-size: 16px;
  margin-bottom: 1px;
}

.messaged p {
  font-size: 14px;
  margin-bottom: 16px;
  color: #646464;
}

.messaged h6 {
  font-size: 12px;
  margin: 0;
  text-align: right;
  color: var(--blue);
}

.ul_set.chat_msg2 li.resssv_us .user_n {
  right: auto;
  left: 0;
}

.ul_set.chat_msg2 li.resssv_us {
  justify-content: start;
  padding-right: 0;
  padding-left: 45px;
}

.ul_set.chat_msg2 li.resssv_us .messaged {
  background: #f9f9f9;
}

.ul_set.chat_msg2 li.resssv_us .messaged h4 {
  color: var(--BL);
}

.ul_set.chat_msg2 li.resssv_us .messaged p {
  color: #646464;
}

.ul_set.chat_msg2 li.resssv_us .messaged h6 {
  color: var(--gr);
}

/* .indexx{
  padding:10px 15px;
} */

.hader_haed {
  background: var(--w);
  border-bottom: 1px solid var(--br);
  padding: 15px 15px;
}

.hader_haed h4 {
  font-size: 21px;
  margin: 0;
}

.left_us {
  width: 50%;
  background: #fff;
  height: calc(100vh - 127px);
  overflow-y: auto;
}

.left_us .ul_set.chat_msg2 li {
  border-bottom: 1px solid var(--br);
  padding: 10px 15px;
  padding-left: 54px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.left_us .ul_set.chat_msg2 li .user_n {
  left: 10px;
  top: 10px;
}

.left_us .ul_set.chat_msg2 li .messaged {
  border-radius: 0;
  background: transparent;
  padding: 0;
  width: 100%;
}

.left_us .ul_set.chat_msg2 li p {
  margin-bottom: 10px;
}

.left_us .ul_set.chat_msg2 li h6 {
  text-align: left;
}

.message_us .open_side {
  position: absolute;
  left: 0;
  top: 0;
  width: 85%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.chat_modal.open_siddde {
  transform: translateX(0);
}

.chat_hadi .open_side {
  color: var(--blue);
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 5px;
}

.indexx {
  position: relative;
  overflow: hidden;
}

.profile {
  padding: 15px;
}


.card_body {
  padding: 20px;
}

.upload {
  border: 2px dashed var(--blue);
  border-radius: 20px;
  padding: 20px 30px;
  text-align: center;
  position: relative;
  z-index: 1;
  background: var(--BG);
}

.upload img {
  width: 65px;
  margin-bottom: 15px;
}

.upload p {
  margin: 0;
  color: var(--gr);
  font-size: 16px;
}

.upload input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  top: 0;
}
.modal-header{
  justify-content: space-between;
}

.modal-header .btn-close {
  padding: 0;
  font-size: 18px;
  background: var(--blue);
  margin: -3px 0;
  color: var(--w);
  opacity: 1;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  position: relative;
}

.modal-header .btn-close::after {
  position: absolute;
  width: 18px;
  height: 18px;
  content: '\e9b3';
  font-family: "unicons-line";
  left: 7px;
  top: 5px;
  background-repeat: no-repeat;
  font-size: 21px;
}

.modal-header .modal-title {
  margin-bottom: 0;
  font-family: "PopSB";
  font-size: 18px;
}

.modal-dialog {
  max-width: 600px;
}

.modal-dialog.modal-lg {
  max-width: 900px;
}

.ul_set.list_name2 li {
  position: relative;
  min-height: 51px;
  padding: 7px 15px;
  padding-left: 80px;
  color: var(--BL);
  border-bottom: 1px solid var(--br);
  cursor: pointer;
}

.ul_set.list_name2 li b {
  position: absolute;
  left: 0;
  font-size: 22px;
  color: var(--gr);
  top: 12px;
}

.ul_set.list_name2 .user {
  position: initial;
  padding-left: 0;
}

.ul_set.list_name2 .user span {
  font-size: 15px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  left: 35px;
  top: 7px;
}

.ul_set.list_name2 .user h4 {
  margin: 0;
  margin-top: 7px;
  font-size: 16px;
}

.ul_set.list_name2 {
  max-height: 300px;
  overflow-y: auto;
}

.select_ser .user {
  display: inline-block;
  background: var(--w);
  padding: 5px 8px;
  border-radius: 40px;
  padding-left: 42px;
  padding-right: 27px;
  min-height: 33px;
  border: 1px solid var(--br);
  margin: 3px;
  cursor: pointer;
}

.select_ser .user span {
  font-size: 13px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  left: 5px;
  top: 3px;
}

.select_ser .user h4 {
  font-size: 15px;
}

.select_ser .user .close_h {
  right: 8px;
  top: 10px;
}

.select_ser {
  overflow-y: auto;
  max-height: 100px;
  margin-bottom: 15px;
}


.Search {
  padding: 10px 10px;
  border: 1px solid var(--br);
  margin: 25px -16px;
}

.Search .form-control {
  border-radius: 100px;
}

.header_list {
  position: absolute;
  right: 0;
  bottom: -1px;
  margin: 0 !important;
}

.header_list li {
  float: left;
  margin: 0 !important;
}

.header_list li a {
  color: var(--gr);
  padding: 10px 20px;
  font-size: 15px;
  display: inline-block;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.header_list li.active a {
  border-color: var(--blue);
  color: var(--blue) !important;
}

.message_us.active,
.message_us:hover {
  border-color: var(--blue);
}


.table_desi {
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  /* overflow: hidden; */
  background: var(--w);
  padding: 15px;
}

.table_desi .table {
  /* border: 1px solid var(--br) !important; */
  border-radius: 0;
  margin-bottom: 0;
  background: var(--w);
  border: 1px solid var(--br) !important;

}

.table_desi .table tr th,
.table_desi .table tr td {
  color: var(--BL);
  border-bottom: 1px solid #e2e8f0;
  padding: 10px 15px;
  font-size: 14px;
  color: var(--gr);
  border-radius: 0 !important;
}

.table_desi .table tr th {
  background: #e2e8f0;
  font-size: 15px;
  color: var(--BL);
  font-weight: normal;
  font-family: "PopM";
}



.table_desi .table> :not(:last-child)> :last-child>* {
  border-bottom-color: var(--br);
}

/* .table_desi .table tr th:nth-last-child(1) {
  border-top-right-radius: 10px;
}

.table_desi .table tr th:nth-child(1) {
  border-top-left-radius: 10px;
}

.table_desi .table tr td:nth-last-child(1) {
  border-bottom-right-radius: 10px;
}

.table_desi .table tr td:nth-child(1) {
  border-bottom-left-radius: 10px;
} */

.main_head {
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 21px;
}

.card_me {
  box-shadow: var(--s1);
  border-radius: 10px;
  background: var(--w);
}

.des_1 {
  display: flex;
  align-items: center;
}

.des_1 .form-control {
  width: 150px;
  margin-left: 10px;
}

.icon_tb i {
  font-size: 23px;
  color: var(--gr);
  margin: 0 4px;
}

.icon_tb i.uil-edit {
  color: #35B8F0;
}

.icon_tb i.uil-trash-alt {
  color: #f00;
}

.des_ss {
  display: flex;
  justify-content: space-between;
}

.dash_box1 {
  position: relative;
  padding: 15px;
  padding-left: 70px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: var(--s1);
  background: var(--w);
}

.dash_box1 .img_icon {
  width: 43px;
  height: 43px;
  border-radius: 100%;
  position: absolute;
  left: 15px;
  top: 15px;
}

.dash_box1 h1 {
  font-size: 21px;
  margin-bottom: 0;
}

.dash_box1 p {
  margin-bottom: 0;
  font-size: 15px;
}

.right_mennu ul>li {
  display: inline-block;
  padding: 0 8px;
}

.right_mennu ul>li>a {
  font-size: 16px;
  color: var(--gr);
}

.right_mennu ul>li>a>i {
  font-size: 24px;
}

.right_mennu ul>li.user_profile>a::after {
  display: none;
}

.right_mennu>ul {
  display: flex;
  align-items: center;
}


.check_b {
  position: relative;
  padding-left: 28px;
  min-height: 20px;
}

.check_b input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  top: 0;
}

.check_b span {
  position: absolute;
  border: 2px solid var(--br);
  width: 20px;
  height: 20px;
  display: inline-block;
  left: 0;
  top: 3px;
}

.check_b input:checked~span::after {
  position: absolute;
  content: "\f00c";
  font-family: "FontAwesome";
  top: -5px;
  font-size: 13px;
  left: 2px;
  color: var(--blue);
}

.check_b input:checked~span {
  border-color: var(--blue);
}

.check_b.radioo span::after {
  display: none;
}

.check_b.radioo span {
  border-radius: 100%;
  border-color: var(--gr);
}

.check_b.radioo span::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: var(--gr);
  border-radius: 100%;
  top: 3px;
  left: 3px;
}

.check_b input:checked~span::before {
  background: var(--blue);
}

.sarch_me.icon_in .form-control {
  background: #eff3f7 !important;
  border-radius: 40px;
  width: 440px;
}

.btn.btn_clear {
  padding: 0 5px;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.table_desi .btn.btn_clear img {
  width: 22px;
}

.switchh {
  width: 55px;
  display: inline-block;
  vertical-align: middle;
  height: 26px;
}

.switchh input[type="checkbox"] {
  position: relative;
  width: 100%;
  background: gainsboro;
  -webkit-appearance: none;
  height: 25px;
  background: #efefef;
  outline: none;
  border-radius: 30px;
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}

.switchh input[type="checkbox"]:checked {
  background: #18b101;
}

.switchh input[type="checkbox"]:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  left: -2px;
  background: #fff;
  box-shadow: 0px 1px 6px 1px gainsboro;
  border-radius: 50%;
  top: 0px;
  transform: scale(1.1);
  transition: 0.5s;
}

.switchh input[type="checkbox"]:checked:before {
  left: 30px;
}

.list_license ul li {
  display: flex;
  color: var(--gr);
  padding: 11px 0;
  border-bottom: 1px solid var(--br);
  font-size: 14px;
  margin: 0 -8px;
}

.list_license ul li span:nth-child(1) {
  width: 40%;
  color: var(--BL);
  font-family: "PopM";
}

.list_license ul li span:nth-child(2) {
  width: 60%;
}

.list_license {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

.list_license ul li span {
  padding: 0 8px;
}


pagination-controls pagination-template {
  float: right;
  margin-top: 15px;
}

pagination-controls pagination-template .ngx-pagination .current {
  background: var(--blue);
}

pagination-controls pagination-template .ngx-pagination a {
  color: var(--BL) !important;
}

.table_desi .table {
  width: 100% !important;
  margin: 0 !important;
}


.small_in {
  width: 130px;
  height: 35px;
  font-size: 15px !important;
}

.btn_same {
  /* width: 96px; */
  font-size: 13px;
  padding: 3px 8px;
  white-space: nowrap;
}

.btn_same1 {
  font-size: 13px;
  padding: 3px 8px;
  margin-bottom: 3px;
  white-space: nowrap;
}

.border_none td {
  border: none;
}

.table tr td {
  color: var(--gr);
}

.table tr th {
  font-weight: normal;
  font-family: "PopM";
}

.table tr th,
.table tr td {
  padding: 7px 10px;
}

.row_sm {
  margin: 0 -7px;
}

.row_sm [class*="col-"] {
  padding: 0 7px;
}

.ad_remove,
.ad_remove a {
  display: inline-block;
}

.price_us,
.white_space {
  white-space: nowrap;
}

.search_top .form-control {
  height: 35px !important;
  font-size: 12px !important;
  padding: 6px 10px;
}

.select_us_search .form-control {
  width: 70px;
  background-size: 10px;
}

.search_top label {
  margin-right: 8px;
  font-size: 12px;
  margin-bottom: 0;
}

.search_left .form-control {
  width: 250px;
}

.search_left,
.select_us_search {
  display: flex;
  align-items: center;
}

.search_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search_left .btn {
  margin-left: 5px;
}

.whit_space .table tr th,
.whit_space .table tr td {
  white-space: nowrap !important;
}

.shor_number {
  margin-top: 10px;
  margin-bottom: 10px;
}

.adm_quary tr td:nth-child(5) {
  width: 600px;
  white-space: normal !important;
}
.deist_cont {
	width: 400px;
	display: block;
	white-space: initial;
}
.list_gra2 {
	display: flex;
	flex-wrap: wrap;
}
.list_gra2 li {
	color: var(--gr);
	max-width: 25%;
	flex: 0 0 25%;
	font-size: 15px;
	margin-bottom: 7px;
}
.list_gra2 li b {
	color: var(--BL);
}
.list_gra2 li.des_full{
  max-width: 50%;
	flex: 0 0 50%;
}
.tab_desg {
	background: var(--w);
	box-shadow: 0 4px 10px rgba(0,0,0,0.08);
	margin-bottom: 20px;
}
.tab_desg .nav-tabs {
	border: none !important;
	box-shadow: none !important;
}
.tab_desg .nav-link {
	padding: 13px 20px;
	border: none !important;
	color: var(--gr);
	font-size: 15px;
  border-bottom: 2px solid transparent !important;
}
.tab_desg .nav-link.active{
  color: var(--blue);
  border-color: var(--blue) !important;
}
.search_close {
	position: relative;
}
.search_close i {
	font-size: 21px;
	color: red;
	position: absolute;
	right: 5px;
	top: 6px;
	/* transform: translateY(-50%); */
}
.search_close input {
	padding-right: 30px !important;
}
.drop_table2:after {
  margin-left: 6px;
  transform: translateY(2px);
}
.tabl_droup .dataTables_scrollBody{
  min-height: 320px;
}
.sraech_minner {
  display: flex;
  margin-right: auto;
}
.sraech_minner .icon_in input {
  padding-left: 25px;
  width: 225px;
}
.sraech_minner .icon_in i {
  font-size: 17px;
  left: 6px;
  top: 5px;
}
.sraech_minner .date_selectt {
  width: 230px;
  margin-left: 10px;
}
.sraech_minner select {
  width: 120px;
  margin-left: 10px;
}
.rules_set.package_persmission {
  height: 285px;
  overflow-x:hidden;
  overflow-y: auto;
  width: 100%;
}
.rules_set.package_persmission h4 {
	font-size: 15px;
	margin-bottom: 12px;
}
.rules_set.package_persmission h4 input {
	margin-right: 4px;
}
.rules_set.package_persmission .row span {
	display: inline-block;
}
.rules_set.package_persmission .row span input {
	margin-right: 4px;
}
.rules_set.package_persmission .ul_set {
	border-bottom: 1px solid var(--br);
	padding-bottom: 10px;
	margin-bottom: 15px;
}
.rules_set.package_persmission #datas {
  padding-left: 20px;
}
.img_tab_2, .img_tab_1 {
	object-fit: contain;
}
/*Copy Website */
.card_me2 {
  background: var(--w);
  border-radius: 15px;
  /* box-shadow: 7px 7px 15px rgba(0,0,0,0.1); */
  padding: 20px 15px;
  border: 1px solid var(--br);
  /* border-top: 5px solid var(--BL2); */
}

.card_me2 h3 {
  color: var(--BL);
  margin-bottom: 20px;
  font-size: 22px;
}

.card_me2 i {
  cursor: pointer;
  font-size: 40px;
  margin-bottom: 10px;
  display: block;
  color: var(--BL);
  /* color: var(--blue); */
}

.card_me2 .select_us {
  text-align: left;
  margin-bottom: 15px;
}
/* Copy Website */
.sidebar .ul_set.scroller_list {
	height: calc(100svh - 130px);
	overflow: auto;
}

.ul_set.list_plus {
	padding: 15px 15px;
}

.ul_set.list_plus li {
	position: relative;
	padding-right: 40px;
	min-height: 45px;
}

.ul_set.list_plus li .plus_miis4 {
	font-size: 30px;
	position: absolute;
	right: 0;
	top: 8px;
}

.ul_set.list_plus li i{
  cursor: pointer;
}

.ul_set.list_plus li .plus_miis4 .uil-minus-circle{
  color: red;
}
.ul_set.list_plus li .plus_miis4 .uil-plus-circle{
  color: var(--blue)
}
.icon_font_size_plus, .icon_font_size {
  margin-top: 7px;
  display: block;
}
.table_desi .table-responsive {
	max-height: 550px;
}
.table_desi .table-responsive thead {
	position: sticky;
	top: 0;
	z-index: 99;
}
.dataTables_scroll .dataTables_scrollHead, .dataTables_scroll .dataTables_scrollBody {
	overflow: initial !important;
}
.dataTables_scroll {
	overflow: auto;
}
.search_left input, .search_left .btn { 
	margin-bottom: 5px;
}
.select_us_search {
	margin-bottom: 10px;
}
.sraech_minner{
  flex-wrap: wrap;
}
.search_top{
  flex-wrap: wrap;
}
.sraech_minner .icon_in {
	margin-bottom: 10px;
}
.sraech_minner .form-control{
  margin-bottom: 10px;
}
.nav_me.tab_design ul li {
	margin: 0;
}
.features_droup .container {
	max-width: 100%;
}
.features_droup .container .col-3 {
	align-items: start !important;
}
.features_droup .container .col-3 input {
	margin-top: 6px;
}
.chart_all .card_me {
	padding: 20px;
}
.chart_all .card_me h4 {
	margin: 0 -20px;
	border-bottom: 1px solid var(--br);
	margin-top: -20px;
	padding: 15px 20px;
	margin-bottom: 20px;
}
.ul_set.list_chartd {
	max-height: 380px;
	border: 1px solid var(--br);
	padding: 10px;
	overflow-y: auto;
  margin-bottom: 0;
}

.ul_set.list_chartd li {
	font-size: 13px;
	margin-bottom: 8px;
	display: flex;
	justify-content: space-between;
  align-items: start;
  color: var(--gr);
  cursor: pointer;
  line-height: 20px;
}
.ul_set.list_chartd li span {
	background: #00B33612;
	width: 35px;
	text-align: center;
	border-radius: 4px;
	font-size: 11px;
	color: #00B336;
}

.ul_set.list_chartd li:nth-child(2n) span {
	color: #f06d0e;
	background: #f06d0e12;
}

.ul_set.list_chartd li:nth-child(3n) span {
	color: #00c6f2;
	background: #00c6f212;
}
.ul_set.list_chartd li:nth-child(4n) span {
	color: #f20000;
	background: #f2000012;
}
.ul_set.list_chartd li:nth-child(5n) span {
	color: #0024e3;
	background: #0024e312;
}

.box_cont {
	background: #4bc25a0f;
	border-left: 3px solid #4bc25a;
	padding: 5px 15px;
}
.box_cont h3 {
	font-size: 13px;
	font-family: "PopR";
	color: #6c6c6c;
	margin: 0;
	line-height: 20px;
}

.box_cont h3 span {
	display: block;
	font-size: 17px;
	font-family: "PopSB";
	color: var(--BL);
}
.box_cont#cancel{
  background: #ca00000f;
	border-left: 3px solid #ca0000;
}

.box_cont#free{
  background: #7400ff0f;
	border-left: 3px solid #7400ff;
}
.channge_table .table_desi {
	margin-top: 20px;
	box-shadow: none;
	padding: 0;
}

.channge_table .table_desi h3 {
	font-size: 20px;
}

.channge_table .table_desi .table-responsive{
  max-height: 350px;
}

.template_view_change {
  border: none !important;
  padding: 0 !important;
  background: transparent !important;
  border-radius: 0 !important;
  overflow: initial;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.template_view_change > h4.img_eyyy {
  color: var(--BL);
  margin: 0;
  padding: 0;
  background: transparent;
  text-align: center;
  justify-content: center;
  margin-bottom: 20px;
}
.template_view_change > h4.img_eyyy img{
  display: none;
}
.template_view_change .card3 {
  position: relative;
  box-shadow: 2px 20px 43px rgba(0,0,0,0.2);
  display: inline-block;
  border: 5px solid var(--w);
  border-radius: 45px;
  padding: 0;
  overflow: hidden;
  width: 100%;
}
.template_view_change .card2 {
  border: none;
  border-radius: 0;
  padding: 15px;
  background: url(assets/img/whatsapp_bg.png);
  height: 460px;
overflow-y: auto;
}
.template_view_change {
	max-width: 280px;
}
.template_view_change .head_top_w1 {
	height: 50px;
}
.template_view_change .card2 {
	height: 370px;
}
.template_view_change .footer_top_w1 {
	height: 60px;
}
.template_view_change .footer_top_w1::before {
	width: 250px;
	height: 22px;
}
.card1.temp_brot .btn {
  margin-bottom: 10px;
}

.footer_top_w1{
  height:75px;
  background: #f6f6f6;
  position: relative;
}
.footer_top_w1::after{
  width: 110px;
  height: 5px;
  border-radius: 10px;
  background: var(--BL2);
  content: "";
  bottom: 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.footer_top_w1::before{
  position: absolute;
  background:url(assets/img/mobile_bar.png);
  content: "";
  top: 0;
  width: 250px;
  height: 22px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-size: 100%;
top: 7px;
}

.footer_top_w1::after {
  width: 110px;
  height: 5px;
  border-radius: 10px;
  background: #333;
  content: "";
  bottom: 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* scroll design */
* {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 transparent;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  background-color:transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
*::-webkit-scrollbar-track, *::-webkit-scrollbar-thumb {
  border-radius: 12px;
}
/* scroll design */
/* mendia */
@media (max-width:1400px) {
     .container .col-3 {
    width: 33%;
  }
}
@media (max-width:1024px) {
  .features_droup .container .col-3 {
    width: 50%;
  }
  .list_gra2 li {
    max-width: 33%;
    flex: 0 0 33%;
  }
}
@media (max-width:767px) {
  .features_droup .container .col-3 {
    width: 100%;
  }
  .main_head .form-control {
    width: 115px !important;
  }
  .main_head .btn {
    padding: 5px 6px !important;
    height: auto !important;
    width: auto !important;
    font-size: 13px !important;
    margin-top: 5px;
  }
  .search_top{
    flex-wrap: wrap;
  }
  .search_left, .select_us_search{
    flex-wrap: wrap;
  }

  .list_gra2 li {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .tab_desg .nav-link {
    font-size: 13px;
    padding: 6px 9px;
  }
  .login {
    padding: 15px;
  }

  .login_box1 {
    padding: 15px;
  }

  .login_img {
    display: none;
  }

  .main_head {
    font-size: 18px;
  }

  .main_head .btn {
    font-size: 14px;
    padding: 4px 10px;
  }

  .table_desi .table tr th {
    font-size: 14px;
    line-height: normal;
    font-weight: normal;
  }

  .table_desi .table tr td {
    font-size: 13px;
    line-height: normal;
  }

  .right_mennu ul>li {
    padding: 0 3px;
  }

  /* .sarch_me.icon_in {
      display: none;
  } */
  .sarch_me.icon_in {
    margin: 0 15px;
  }

  .table_desi .table tr td:nth-last-child(1) {
    white-space: nowrap;
  }

  .switchh {
    width: 35px;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
  }

  .switchh input[type="checkbox"] {
    height: 100%;
  }

  .switchh input[type="checkbox"]::before {
    width: 15px;
    height: 15px;
    top: 2px;
    box-shadow: 0px 0px 0px 1px gainsboro;
  }

  .switchh input[type="checkbox"]:checked::before {
    left: 20px;
  }

  label {
    font-size: 14px;
  }

  .form-control {
    font-size: 14px !important;
    height: 40px;
  }

  .card_body {
    padding: 15px;
  }
}


@media (max-width:991px) {
  .header {
    justify-content: space-between;
  }

  .menu {
    display: block;
  }

  .conten_main {
    padding-left: 0;
  }

  .sidebar {
    left: -100%;
    padding-top: 70px;
    height: calc(100% - 70px);
    top: 70px;
    padding-top: 0;
  }

  .header {
    width: 100%;
    left: 0;
  }

  .conten_main.conten_set>.p-4 {
    padding: 15px !important;
  }

  .menu img {
    width: 130px;
  }

  .sidebar.open_siddde {
    left: 0;
  }

  .logo {
    display: none;
  }
}

/* mendia Close */
  .clickable {
    cursor: pointer
  }

input#phone {
  width: 1px !important;
}